<template>
  <div class="main">
    <div class="wrapper">
      <div class="mb-10">
        <v-container grid-list-xs>
          <v-row>
            <v-col>
              <div class="d-flex align-center" style="margin-top: -25px">
                <v-icon
                  size="25"
                  class="my-5"
                  color="grey-300"
                  @click="navigateToProduct"
                  >mdi-arrow-left</v-icon
                >
                <div class="flex-grow-1 text-center">
                  <h4 class="ma-4 pa-4 grey--text" v-if="tableName">
                    Table {{ tableName }}
                  </h4>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card color="white" flat>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <div
                      style="margin-top: -50px"
                      width="390"
                      height="232"
                      v-if="selectedProduct.media_urls[0]"
                    >
                      <v-img
                        class="rounded-lg my-5"
                        contain
                        v-if="selectedProduct.media_urls"
                        :src="image(selectedProduct.media_urls[0])"
                        style="
                          width: 100%;
                          height: 100%;
                          max-width: 100%;
                          max-height: 100%;
                        "
                      ></v-img>
                    </div>
                    <div v-else>
                      <v-img
                        class="rounded-lg my-5"
                        contain
                        v-if="selectedProduct.media_urls"
                        :src="emptyImage()"
                        style="
                          width: 100%;
                          height: 100%;
                          max-width: 100%;
                          max-height: 100%;
                        "
                      ></v-img>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0"> </v-col>
                </v-row>
              </v-card>
              <v-card rounded="xl" elevation="5">
                <div class="mx-3 py-5">
                  <div
                    style="
                      box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
                      padding-bottom: 4px;
                    "
                  >
                    <div class="d-flex justify-space-between">
                      <div
                        class="font-weight-bold"
                        style="
                          font-size: 18px;
                          width: 210px;
                          margin-bottom: 5px;
                        "
                      >
                        {{ selectedProduct.name }}
                      </div>
                      <div class="d-flex justify-space-between">
                        <v-rating
                          background-color="yellow"
                          length="1"
                          color="yellow"
                          readonly
                          size="15"
                          :value="averageRating"
                          style="margin-top: -6px"
                        ></v-rating>
                        <h6
                          class="grey--text"
                          style="margin-top: 3px; margin-left: -4px"
                        >
                          4.8
                        </h6>
                      </div>

                      <div class="flex flex-column source text-right">
                        <h5
                          v-if="selectedProduct.before_discount"
                          class="blue-grey--text text--lighten-3 line-through red--text"
                        >
                          RM
                          {{
                            parseFloat(selectedProduct.before_discount).toFixed(
                              2
                            )
                          }}
                        </h5>
                        <h5 v-if="this.selectedVariants != 0" class="red--text">
                          {{
                            selectedProduct.price_type == 0
                              ? "RM" +
                                parseFloat(
                                  this.selectedVariants[0].price
                                ).toFixed(2)
                              : ""
                          }}
                        </h5>
                        <h5 class="red--text" v-else>
                          {{
                            "RM" +
                            parseFloat(selectedProduct.unit_price).toFixed(2)
                          }}
                        </h5>
                      </div>
                    </div>

                    <div v-if="selectedProduct.description">
                      <!-- <h5 class="nunito font-weight-bold mb-1">Description</h5> -->
                      <p class="nunito" style="width: 75%">
                        {{ selectedProduct.description }}
                      </p>
                    </div>
                  </div>
                  <div v-if="selectedProduct.total_left">
                    <h5 class="nunito font-weight-bold mb-1">Stocks</h5>
                    <h4 class="nunito font-weight-bold mb-3">
                      {{ selectedProduct.total_left }} left
                    </h4>
                  </div>
                  <div
                    v-if="
                      selectedProduct.variants.length > 0 &&
                      selectedProduct.price_type == 0
                    "
                    style="box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2)"
                  >
                    <div
                      style="color: #f85757; font-size: 16px"
                      class="nunito mb-1 mt-4"
                    >
                      Variants
                    </div>
                    <v-divider></v-divider>
                    <div
                      v-if="
                        selectedProduct.variant &&
                        selectedProduct.price_type == 0
                      "
                      class="flex overflow-auto"
                    >
                      <div
                        v-for="(value, i) in selectedProduct.variants"
                        :key="i"
                      >
                        <v-card
                          class="pa-3 my-3 mr-3"
                          :color="
                            selectedVariants.length > 0
                              ? selectedVariants[0].id == value.id
                                ? 'red white--text'
                                : 'white'
                              : 'white'
                          "
                          @click="selectVariants(value, i)"
                        >
                          <div class="flex flex-column">
                            <h5 class="nunito">{{ value.name }}</h5>
                            <h5 class="nunito">{{ showAmt(value) }}</h5>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div v-else class="flex overflow-auto">
                      <div
                        v-for="(value, i) in selectedProduct.variants"
                        :key="i"
                      >
                        <v-card
                          class="pa-3 my-3 mr-3"
                          :color="
                            selectedVariants.length > 0
                              ? selectedVariants[0].id == value.id
                                ? 'red white--text'
                                : 'white'
                              : 'white'
                          "
                          @click="selectVariants(value, i)"
                        >
                          <div class="flex flex-column">
                            <h5
                              class="nunito"
                              style="text-transform: capitalize !important"
                            >
                              {{ value.name }}
                            </h5>
                            <h5 class="nunito">{{ showAmt(value) }}</h5>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="selectedProduct.modifier_sets.length > 0"
                    class="mt-6"
                  >
                    <div
                      v-for="(value, i) in sortedItems(
                        JSON.parse(
                          JSON.stringify(selectedProduct.modifier_sets)
                        )
                      )"
                      :key="i"
                    >
                      <v-row class="px-3">
                        <div class="flex justify-between align-center">
                          <div
                            class="name-mod nunito red--text"
                            style="font-size: 16px"
                          >
                            {{ value.name }}
                          </div>
                          <div
                            class="max_min_mod"
                            style="
                              font-size: 13px;
                              margin-left: auto;
                              white-space: nowrap;
                            "
                          >
                            Min {{ value.min_apply_limit }}, Max
                            {{ value.max_apply_limit }}
                          </div>
                        </div>
                      </v-row>
                      <br />
                      <v-divider></v-divider>
                      <div
                        v-if="shouldExpandModifier(i)"
                        class="flex mt-2 mb-6"
                        style="box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2)"
                      >
                        <div class="d-flex flex-column" style="width: 100%">
                          <div
                            v-for="(n, index) in value.modifiers"
                            :key="index"
                          >
                            <div
                              class="d-flex align-center"
                              style="width: 100%"
                            >
                              <div>
                                <div
                                  :class="
                                    renderModifierTotal(n) > 0
                                      ? 'product_detail_modifier_total_active'
                                      : 'product_detail_modifier_total_not_active'
                                  "
                                  v-show="renderModifierTotal(n) > 0"
                                >
                                  {{ renderModifierTotal(n) }}
                                </div>
                              </div>
                              <!-- <v-icon color="red" size="30" @click="selectModifier(n, i, value.max_apply_limit)">mdi-checkbox-blank-outline</v-icon> -->

                              <div class="mx-2">{{ showModifier(n) }}</div>
                              <div
                                class="d-flex mt-3"
                                style="margin-left: auto"
                              >
                                <v-btn-toggle dense class="mb-2">
                                  <button
                                    :disabled="renderModifierTotal(n) == 0"
                                    @click.stop="handleRemoveModifier(n)"
                                    :class="
                                      renderModifierTotal(n) > 0
                                        ? 'product_detail_modifier_button'
                                        : 'product_detail_modifier_button_disabled'
                                    "
                                    style="width: 16px; height: 16px"
                                  >
                                    <v-icon size="15" class="white--text"
                                      >mdi-minus</v-icon
                                    >
                                  </button>
                                  <v-sheet
                                    class="px-5 pt-1"
                                    style="height: 16px"
                                  >
                                    <span class="mt-1 black--text">
                                      <div style="font-size: 14px !important">
                                        {{ n.amount.toFixed(2) }}
                                      </div>
                                    </span>
                                  </v-sheet>
                                  <button
                                    class="product_detail_modifier_button"
                                    style="width: 16px; height: 16px"
                                    @click.stop="
                                      handleAddModifier(
                                        n,
                                        value.max_apply_limit
                                      )
                                    "
                                  >
                                    <v-icon size="15" class="white--text"
                                      >mdi-plus</v-icon
                                    >
                                  </button>
                                </v-btn-toggle>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      selectedProduct.variants.length > 0 &&
                      selectedProduct.price_type == 0
                    "
                  ></div>

                  <div class="my-5">
                    <h5 class="nunito mb-2 font-weight-bold">Special Note</h5>
                  </div>
                  <div>
                    <v-textarea
                      outlined
                      color="red"
                      name="input-7-4"
                      placeholder="Add your request"
                      flat
                      v-model="remark"
                      dense
                      :rows="2"
                    ></v-textarea>
                  </div>
                </div>
                <div
                  class="flex flex-column"
                  style="margin-top: -20px; margin-bottom: 20px"
                >
                  <v-btn-toggle
                    color="#F85757"
                    dense
                    class="flex justify-center"
                  >
                    <button
                      @click="quantity === 0 ? 0 : quantity--"
                      class="product_detail_modifier_button"
                    >
                      <v-icon small class="white--text">mdi-minus</v-icon>
                    </button>
                    <v-sheet class="px-5 pt-1">
                      <span class="mt-1 red--text">{{ quantity }}</span>
                    </v-sheet>

                    <button
                      class="product_detail_modifier_button"
                      @click="
                        selectedProduct.total_left &&
                        quantity > selectedProduct.total_left - 1
                          ? quantity
                          : quantity++
                      "
                    >
                      <v-icon small class="white--text">mdi-plus</v-icon>
                    </button>
                  </v-btn-toggle>
                </div>

                <div class="product_add_to_cart_button">
                  <v-btn
                    block
                    color="#F85757"
                    style="height: 65px"
                    class="d-flex justify-space-between pa-2 pr-6 pl-6"
                    dark
                    @click="addCart(selectedProduct)"
                    rounded-xl
                    elevation="0"
                  >
                    <!-- <v-icon class="mr-2">mdi-cart-plus</v-icon> -->
                    <div style="text-transform: none; font-size: 15px">
                      In Cart | {{ quantity }} Item
                    </div>
                    <div>{{ calculateTotalPrice() }}</div>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Product",

  data() {
    return {
      quantity: 1,
      selectedProduct: null,
      selectedQuantity: [],
      selectedVariants: [],
      isRemark: false,
      remark: "",
      averageRating: null,
      tableName: undefined,
      modifierExpandedList: [0], // default expanded modifier is first modifier
    };
  },

  computed: {
    ...mapGetters({
      product: "getSelectedProduct",
      franchise: "getFranchise",
      productReview: "getProductReview",
      cartIndex: "getEditCartIndex",
    }),
  },
  watch: {
    productReview: function () {
      this.calculateRating();
    },
  },

  methods: {
    shouldExpandModifier(modifierIdx) {
      return this.modifierExpandedList.includes(modifierIdx);
    },
    toggleModifier(i) {
      if (this.modifierExpandedList.includes(i)) {
        this.modifierExpandedList = this.modifierExpandedList.filter(
          (item) => item !== i
        );
      } else {
        this.modifierExpandedList.push(i);
      }
    },
    goToUserDetail() {
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if (outlet_id && table_id) {
        // STATIC QR
        this.$router.push({
          name: "StaticUser",
          params: {
            child: this.$route.params.child,
            outlet_id,
            table_id,
            page: "user",
          },
        });
      } else if (session) {
        this.$router.push({
          name: "SessionUser",
          params: { session },
        });
      } else {
        // SHOP_LINK
        this.$router.push({
          name: "ShoplinkUser",
          params: { child: this.$route.params.child, page: "user" },
        });
      }
    },
    navigateToProduct() {
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      // STATIC_QR
      if ((outlet_id, table_id)) {
        this.$router
          .push({
            name: "Products",
            params: {
              child: this.$route.params.child,
              outlet_id,
              table_id,
              page: "products",
            },
          })
          .then(() => {
            setTimeout(() => {
              window.scrollTo(0, this.$store.getters.getScrollPosition);
            }, 100);
          });
      } else if (session) {
        // SESSION QR
        this.$router
          .push({
            name: "SessionProducts",
            params: { session },
          })
          .then(() => {
            // Restore scroll position setelah navigasi
            setTimeout(() => {
              window.scrollTo(0, this.$store.getters.getScrollPosition);
            }, 100);
          });
      } else {
        // SHOP_LINK
        this.$router
          .push({
            name: "ShoplinkProducts",
            params: { child: this.$route.params.child, page: "products" },
          })
          .then(() => {
            setTimeout(() => {
              window.scrollTo(0, this.$store.getters.getScrollPosition);
            }, 100);
          });
      }
      // this.$router.push({
      //   name: "Page",
      //   params: { child: this.$route.params.child, page: "products" },
      // });
      // localStorage.removeItem("selectedProduct");
    },

    handleAddModifier(modifiers, max_apply_limit) {
      const modifierByCurrentCategory = this.selectedQuantity.filter(
        (item) => item.set_id === modifiers.set_id
      );
      if (modifierByCurrentCategory.length < max_apply_limit) {
        this.selectedQuantity.push(modifiers);
      }
    },
    renderModifierTotal(modifiers) {
      const modifierByCurrentCategory = this.selectedQuantity.filter(
        (item) => item.id === modifiers.id
      );
      return modifierByCurrentCategory.length;
    },
    renderModifierTotalAmount(modifiers, total) {
      return modifiers * total;
    },
    handleRemoveModifier(modifiers) {
      let arr = this.selectedQuantity;
      var modifierIndex = arr.findIndex((item) => item.id == modifiers.id);
      arr.splice(modifierIndex, 1);
      console.log(JSON.stringify(arr));
      this.selectedQuantity = arr;
    },
    getout(v, i) {
      console.log(v, i);
    },
    sortedItems(items) {
      console.log("item", items);
      return items.sort((a, b) => a.name.localeCompare(b.name));
    },

    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },

    back() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "products" },
      });
    },

    showModifier(mod) {
      if (typeof mod.amount == "string") {
        mod.amount = parseFloat(mod.amount);
      }
      return `${mod.name}`;
    },

    showAmt(vars) {
      if (typeof vars.price == "string") {
        vars.price = parseFloat(vars.price);
      }

      var amt = vars.price > 0 ? ` RM${vars.price.toFixed(2)}` : "";
      return `${amt}`;
    },

    selectModifier(v, i, max_apply_limit) {
      // find previus selected modifier
      const modifierByCurrentCategory = this.selectedQuantity.filter(
        (item) => item.set_id === v.set_id
      );
      if (modifierByCurrentCategory.length === max_apply_limit) {
        let removeModifierByCurrentCategory = this.selectedQuantity.filter(
          (item) => item.set_id !== v.set_id
        );
        this.selectedQuantity = [...removeModifierByCurrentCategory, v];
      } else {
        if (this.selectedQuantity.some((d) => d.id == v.id)) {
          var p = this.selectedQuantity.filter(function (x) {
            return x.id != v.id;
          });
          this.selectedQuantity = p;
        } else {
          this.selectedQuantity.push(v);
        }
      }
    },

    selectVariants(values) {
      if (values) {
        var val = {
          id: values.id,
          name: values.name,
          price: values.price,
          code: values.code,
        };
        this.selectedVariants = [val];
      }
    },

    limitModifiers(uuid, qty) {
      let cart_list = [];
      let adding = () => {
        this.product.order_id = uuid;
        this.product.quantity = qty;
        cart_list = [this.selectedQuantity, ...cart_list];
        window.fbq("track", "AddToCart");
        // close();
      };

      if (this.product.modifier_sets.length > 0) {
        let k = this.product.modifier_sets.map((d) => {
          // console.log(i, "i");
          let z = this.selectedQuantity.filter((l) =>
            d.modifiers.map((j) => j.id).includes(l.id)
          );
          let ret = {
            status: true,
            message: "",
          };

          if (z.length > d.max_apply_limit && d.max_apply_limit != 0) {
            ret.status = false;
            ret.message =
              "Please choose not more than " +
              d.max_apply_limit +
              " for " +
              d.name;
            return ret;
          } else if (z.length < d.min_apply_limit != 0) {
            ret.status = false;
            ret.message =
              "Please choose minimum of " +
              d.min_apply_limit +
              " for " +
              d.name;
            return ret;
          } else return ret;
        });

        let error = k.filter((d) => !d.status);

        if (error.length > 0) {
          alert(error[0].message);
          return false;
        } else {
          adding();
        }
      } else {
        adding();
      }
    },

    async addCart(i) {
      var k = Object.assign(this.product);

      if (!k.order_id) {
        i.order_id = await this.$store.dispatch("uuid");
      }
      let t = null;
      // if (this.franchise.outlets.length > 0) {
      //   t = this.limitModifiers(i.order_id, this.quantity);
      // }
      t = this.limitModifiers(i.order_id, this.quantity);

      if (t != false || t == null) {
        i.quantity = this.quantity;
        i.remarks = this.remark;
        i.modifiers = this.selectedQuantity || null;
        if (this.selectedVariants.length == 0) {
          this.selectVariants(k.variants[0]);
          i.variant = this.selectedVariants || null;
        } else {
          i.variant = this.selectedVariants || null;
        }
        this.quantity = 1;
        this.remark = "";
        this.track(i.name);
        if (this.cartIndex !== null) {
          const payload = {
            from: "edit_cart",
            value: i,
          };
          this.$store.commit("addToCart", payload);
        } else {
          this.$store.commit("addToCart", i);
        }
        this.navigateToProduct();

        // this.$router.go(-1);
      } else {
        return;
      }
    },

    track(val) {
      this.$gtag.event("add_to_cart", {
        event_category: "add_to_cart",
        event_label: "add_to_cart",
        value: val,
      });
    },

    calculateTotalPrice() {
      if (this.selectedProduct.variants.length > 0) {
        if (this.selectedQuantity.length > 0) {
          let total = 0;
          this.selectedQuantity.map((r) => {
            total += r.amount;
          });
          return (
            (this.selectedVariants[0].price + total) *
            this.quantity
          ).toFixed(2);
        }
        return (this.selectedVariants[0].price * this.quantity).toFixed(2);
      } else {
        if (this.selectedQuantity.length > 0) {
          let total = 0;
          this.selectedQuantity.map((r) => {
            total += r.amount;
          });
          return (
            (parseFloat(this.selectedProduct.unit_price) + total) *
            this.quantity
          ).toFixed(2);
        } else {
          return (
            parseFloat(this.selectedProduct.unit_price) * this.quantity
          ).toFixed(2);
        }
      }
    },

    calculateRating() {
      var total = 0;
      if (this.productReview.length > 0) {
        this.productReview.map((r) => {
          total += r.rating;
        });
      }

      this.averageRating = total / this.productReview.length;
    },
  },

  beforeMount() {
    var k = { ...this.product };
    this.selectedProduct = k;

    this.modifierExpandedList = this.selectedProduct.modifier_sets.map(
      (_, index) => index
    );

    if (k.variants.length > 0) {
      this.selectedVariants = [k.variants[0]];
    }

    if (k.quantity) {
      this.quantity = k.quantity;
    }

    if (k.remarks) {
      this.isRemark = true;
      this.remark = k.remarks;
    }

    if (k.modifiers) {
      this.selectedQuantity = k.modifiers;
    }

    if (k.variant) {
      this.selectedVariants = k.variant;
    } else {
      var p = k.variants;
      for (let [key, value] of Object.entries(p)) {
        if (value.default) {
          this.selectedVariants = k.variants[key];
          this.selectVariants(this.selectedVariants);
        }
      }
    }

    window.scrollTo(0, 0);

    this.$store.dispatch("fetchReview", this.selectedProduct.id);

    const { outlet_id, table_id } = this.$route.params;
    const { session } = this.$route.params;
    if (outlet_id && table_id) {
      this.tableName = this.franchise.table_selected[0].name || undefined;
    }

    if (session) {
      this.tableName = this.franchise.table_name;
    }
  },
};
</script>
<style lang="scss">
.height {
  height: 100%;
}

.line-through {
  text-decoration: line-through #f85757;
}

.product_detail_modifier_total_active {
  background-color: #f85757;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 27px;
  height: 27px;
  color: white;
}

.product_detail_modifier_total_not_active {
  border: 2px solid #f85757;
  width: 27px;
  height: 27px;
  color: white;
}

.product_detail_modifier_button {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f85757;
  border-radius: 4px;
}

.product_detail_modifier_button_disabled {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f85757;
  border-radius: 4px;
  opacity: 0.4;
}

.red_color {
  color: #f85757 !important;
}

.product_add_to_cart_button {
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
  padding-left: 10px;
  background-color: white;
  padding-bottom: 20px;
  padding-right: 10px;
  z-index: 1000000;
  width: 100%;
}
</style>
